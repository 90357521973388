
<template>
	<div class="no-print">
		<v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
		<v-container v-else class=" no-print my-2 ">
			<h1 class=" py-2 mb-4">Shop Items</h1>

			<form @submit.prevent="addShopItems" autocomplete="off" class="no-print">
				<v-layout row wrap>

					<!-- 																<v-flex xs12 lg3 xl3 md4 sm4>

						<v-text-field v-model="shop_items.shop_item_barcode" type="number" :label="$store.getters.language.data.shop_items.shop_item_barcode" dense class="mx-1" filled outlined>
						</v-text-field>
					</v-flex> -->
					<v-flex xs12 lg2 xl2 md4 sm4>

						<v-select class="mx-1" clearable :items="item_types" v-model="shop_items.item_type_id" dense filled outlined item-text="item_type_title" item-value="item_type_id" :return-object="false" :label="$store.getters.language.data.item_types.item_type_title">
						</v-select>
					</v-flex>

					<v-flex xs12 lg2 xl2 md4 sm4>

						<v-select class="mx-1" clearable :items="item_colors" v-model="shop_items.item_color_id" dense filled outlined item-text="item_color_title" item-value="item_color_id" :return-object="false" :label="$store.getters.language.data.item_colors.item_color_title">
						</v-select>
					</v-flex>

					<v-flex xs12 lg2 xl2 md4 sm4>

						<v-select class="mx-1" clearable :items="item_sizes" v-model="shop_items.item_size_id" dense filled outlined item-text="item_size_title" item-value="item_size_id" :return-object="false" :label="$store.getters.language.data.item_sizes.item_size_title ">
						</v-select>
					</v-flex>
					<v-flex xs12 lg2 xl2 md4 sm4>

						<v-text-field v-model="shop_items.shop_item_quantity" type="number" :label="$store.getters.language.data.shop_items.shop_item_quantity" dense class="mx-1" filled outlined required>
						</v-text-field>
					</v-flex>
					<v-flex xs12 lg2 xl2 md4 sm4>
						<v-text-field v-model="shop_items.shop_item_price" type="text" :label="$store.getters.language.data.shop_items.shop_item_price" dense class="mx-1" filled outlined required>
						</v-text-field>
					</v-flex>
					<v-flex xs12 lg2 xl2 md4 sm4>

						<v-select class="mx-1" clearable :items="['true','false']" v-model="shop_items.shop_item_fav" dense filled outlined item-text="shop_item_fav" item-value="shop_item_fav" :return-object="false" :label="$store.getters.language.data.shop_items.shop_item_fav">
						</v-select>
					</v-flex>

					<v-flex xs12 lg3 xl3 md4 sm4>
						<v-row no-gutters>
							<v-text-field v-model="shop_items.shop_item_link" type="text" :label="$store.getters.language.data.shop_items.shop_item_link" dense class="mx-1" filled outlined>
							</v-text-field>
							<!-- <v-flex xs2 lg2 xl2 md3 sm4 style="text-align:centered"> -->

							<v-btn @click="pasteTextLink" small icon><v-icon>mdi-content-paste</v-icon></v-btn>
						</v-row>
					</v-flex>

					<v-flex xs12 lg3 xl3 md4 sm4>

						<v-text-field v-model="shop_items.shop_item_note" type="text" :label="$store.getters.language.data.shop_items.shop_item_note" dense class="mx-1" filled outlined>
						</v-text-field>
					</v-flex>

					<!-- 																<v-flex xs12 lg3 xl3 md4 sm4>

                                <v-select class="mx-1" clearable  :items="['available','stock','zero_quantity']" v-model="shop_items.shop_item_status" dense  filled outlined  item-text="shop_item_status"
                                    item-value="shop_item_status" :return-object="false" :label="$store.getters.language.data.shop_items.shop_item_status">
                                </v-select>
                        </v-flex> -->

					<!-- 																<v-flex xs12 lg3 xl3 md4 sm4>

                        <v-text-field v-model="shop_items.shop_item_date" type="datetime-local" :label="$store.getters.language.data.shop_items.shop_item_date" dense
                            class="mx-1"  filled outlined  required>
                        </v-text-field>
                    </v-flex> -->

					<v-flex xs12 lg3 xl3 md4 sm4>

						<v-select class="mx-1" clearable :items="users" v-model="shop_items.user_id" dense filled outlined item-text="user_username" item-value="user_id" :return-object="false" :label="$store.getters.language.data.users.user_username" disabled>
						</v-select>
					</v-flex>

					<v-flex xs12 lg2 xl2 md2 sm4>
						<v-btn color="primary" :loading="loading_btn" type="submit">{{$store.getters.language.data.shop_items.add_btn}}</v-btn>
					</v-flex>
				</v-layout>
			</form>
			<v-layout row wrap mt-5 class="no-print">
				<v-flex xs12>
					<v-text-field v-model="search" class="ma-3" append-icon="mdi-magnify" label="search" dense outlined hide-details></v-text-field>

					<v-card>
						<v-card-text>
							<v-data-table :headers="headers" v-model="selected_rows" :search="search" :items="rows" class="elevation-0" item-key="shop_item_id">
								<template v-slot:[`item.shop_item_id`]="{ item }">
									<div>
										{{ item.shop_item_id }}
									</div>
								</template>
								<template v-slot:[`item.shop_item_link`]="{ item }">
									<div>
										<a :href="item.shop_item_link" target="_blank">
											<v-tooltip bottom>
												<template v-slot:activator="{ on }">
													<v-icon v-on="on" v-if="item.shop_item_link != null">mdi-link</v-icon>
													<v-icon v-if="item.shop_item_link == null" color="red" v-on="on">mdi-link</v-icon>
												</template>
												<span>{{ item.shop_item_link }}</span>
											</v-tooltip>

										</a>
									</div>
								</template>
								<template v-slot:[`item.shop_item_date`]="{ item }">
									<div>
										{{ new Date(item.shop_item_date).toISOString().split('T')[0]}} {{ new Date(item.shop_item_date).toISOString().split('T')[1].split('.')[0]}}
									</div>
								</template>
								<template v-slot:[`item.shop_item_barcode`]="{ item }">
									<div>
										<v-btn icon @click="reinsertItem(item)" color="orange" class="mx-1"><v-icon>mdi-restore</v-icon></v-btn>
										<v-btn icon :to="'/shop_items-list/'+item.shop_item_id" color="teal" class="mx-1">
											<v-icon> mdi-pencil-outline </v-icon>
										</v-btn>
										<!-- <v-btn color="error" icon class="mx-1" @click="selectShopItems(item)">
											<v-icon> mdi-delete-outline </v-icon>
										</v-btn> -->
										<v-btn icon class="mx-1" @click="openDialog(item)">
											<v-icon> mdi-image-multiple </v-icon>
										</v-btn>
										<v-btn icon class="mx-1" @click="openDialogHistory(item)">
											<v-icon> mdi-history </v-icon>
										</v-btn>
										<v-btn icon class="mx-1" @click="openDialogMove(item)" color="primary">
											<v-icon> mdi-plus-minus-box </v-icon>
										</v-btn>
										<v-btn icon class="mx-1" @click="openDialogBarcode(item)" color="success">
											<v-icon> mdi-printer </v-icon>
										</v-btn>

									</div>
								</template>
							</v-data-table>
						</v-card-text>

						<v-card-actions v-if="selected_rows.length > 0">
							<v-btn color="error" @click="deleteShopItemsList">{{$store.getters.language.data.shop_items.delete_list_btn}}</v-btn>
						</v-card-actions>

					</v-card>
				</v-flex>
			</v-layout>
		</v-container>
		<v-dialog v-model="delete_dialog" persistent max-width="400">
			<v-card>
				<v-card-title>
					{{$store.getters.language.data.shop_items.delete_question}}
				</v-card-title>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text class="elevation-0" @click="delete_dialog = false">
						{{$store.getters.language.data.shop_items.cancel_btn}}
					</v-btn>
					<v-btn color="error" class="elevation-0" @click="deleteShopItems(selected_shop_items.shop_item_id)">
						{{$store.getters.language.data.shop_items.yes_btn}}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="image_dialog" persistent>
			<v-card>
				<v-card-title>
					Images
				</v-card-title>
				<v-card-text>
					<v-layout row wrap>
						<!-- <v-flex xs12 v-for="image in images">
                            <v-img :src="image.shop_item_image_link" :alt="image.shop_item_image_link" :key="image.shop_item_image_id" width="100%" height="100%"></v-img>
                        </v-flex> -->
						<v-flex xs12 lg2 xl2 md12 sm12 v-for="item,index in fileRows" :key="index">

							<div class="mx-3">
								<v-img max-width="500" v-bind:src="$imageURL + 'items/image/' + item.file_attachment_path" />
							</div>
							<!-- {{item.file_attachment_path}} -->

						</v-flex>
					</v-layout>
					<v-layout row wrap>

						<form @submit.prevent="addFileAttachments" autocomplete="off">

							<v-flex xs12 lg3 xl3 md4 sm4>

								<v-btn :color="img_file == null ? 'secondary' : 'teal'" :outlined="img_file == null" :loading="loading_btn" large style="width:100%" @click="openFile">
									upload
									<v-icon>fas fa-check</v-icon>
								</v-btn>
							</v-flex>
							<v-flex xs12 lg5 xl2 md3 sm4>
								<input type="file" @change="processFile" ref="file" style=" display:none" />
							</v-flex>

							<!-- <v-flex xs12 lg12 xl12 md12 sm12>
								<v-text-field v-model="file_attachments.shop_item_id" label="shop_item_id" type="number" dense filled outlined></v-text-field>
							</v-flex> -->

							<!-- <v-flex xs12 lg12 xl12 md12 sm12>
								<v-select class="mx-1" clearable :items="users" v-model="file_attachments.user_id" dense filled outlined item-text="user_id" item-value="user_id" :return-object="false" :label="$store.getters.language.data.users.user_id">
								</v-select>
							</v-flex> -->

							<v-flex xs12 lg2 xl2 md2 sm4>
								<v-btn color="primary" :loading="loading_btn" type="submit" v-if="img_file != null">{{$store.getters.language.data.file_attachments.add_btn}}</v-btn>
							</v-flex>

						</form>
					</v-layout>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text class="elevation-0" @click="image_dialog = false">
						{{$store.getters.language.data.shop_items.cancel_btn}}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="history_dialog" persistent>
			<v-card>
				<v-card-title>
					History
				</v-card-title>
				<v-card-text>
					<!-- {{shop_item_histories}} -->
					<!-- create a table to sow shop_item_histories data -->
					<table class="report-table" style="width:100%;" border="1">
						<thead>
							<tr>
								<th>{{$store.getters.language.data.shop_items.shop_item_barcode}}</th>
								<th>Date</th>
								<th>Edited by</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(shop_item_history,index) in shop_item_histories" :key="index">
								<td>{{shop_item_history.shop_item_id}}</td>
								<td>
									{{ new Date(shop_item_history.shop_item_history_date).toISOString().split('T')[0]}} {{ new Date(shop_item_history.shop_item_history_date).toISOString().split('T')[1].split('.')[0]}}

								</td>
								<td>{{shop_item_history.user_username}}</td>
							</tr>
						</tbody>
					</table>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text class="elevation-0" @click="history_dialog = false">
						{{$store.getters.language.data.shop_items.cancel_btn}}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="move_dialog" persistent>
			<v-card>
				<v-card-title>
					Item Moves
				</v-card-title>
				<v-card-text>
					<!-- {{shop_item_moves}} -->
					<form @submit.prevent="addShopItemMoves()" autocomplete="off" class="ma-4">
						<v-layout row wrap>

							<v-flex xs12 lg2 xl2 md3 sm4>
								<v-select class="mx-1" clearable :items="['add','loss']" v-model="shop_item_moves_add.shop_item_move_type" dense filled outlined item-text="shop_item_move_type" item-value="shop_item_move_type" :return-object="false" :label="$store.getters.language.data.shop_item_moves.shop_item_move_type">
								</v-select>
							</v-flex>

							<!-- <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="shop_item_moves.shop_item_move_date" type="datetime-local" :label="$store.getters.language.data.shop_item_moves.shop_item_move_date" dense
                            class="mx-1"  filled outlined  required>
                        </v-text-field>
                    </v-flex> -->

							<v-flex xs12 lg2 xl2 md3 sm4>
								<v-text-field v-model="shop_item_moves_add.shop_item_move_quantity" type="number" :label="$store.getters.language.data.shop_item_moves.shop_item_move_quantity" dense class="mx-1" filled outlined required>
								</v-text-field>
							</v-flex>
							<v-flex xs12 lg2 xl2 md3 sm4>
								<v-text-field v-model="shop_item_moves_add.shop_item_move_note" type="text" :label="$store.getters.language.data.shop_item_moves.shop_item_move_note" dense class="mx-1" filled outlined>
								</v-text-field>
							</v-flex>

							<!-- <v-flex xs12 lg2 xl2 md3 sm4>
                                <v-select class="mx-1" clearable  :items="shop_items" v-model="shop_item_moves.shop_item_id" dense  filled outlined  item-text="shop_item_id"
                                    item-value="shop_item_id" :return-object="false" :label="$store.getters.language.data.shop_items.shop_item_id">
                                </v-select>
                        </v-flex> -->

							<!-- <v-flex xs12 lg2 xl2 md3 sm4>
                                <v-select class="mx-1" clearable  :items="users" v-model="shop_item_moves.user_id" dense  filled outlined  item-text="user_id"
                                    item-value="user_id" :return-object="false" :label="$store.getters.language.data.users.user_id">
                                </v-select>
                        </v-flex> -->

							<v-flex xs12 lg2 xl2 md2 sm4>
								<v-btn color="primary" :loading="loading_btn" type="submit">{{$store.getters.language.data.shop_item_moves.add_btn}}</v-btn>
							</v-flex>
						</v-layout>
					</form>
					<!-- create a table to sow shop_item_histories data -->
					<table class="report-table" style="width:100%;" border="1">
						<thead>
							<tr>
								<th>{{$store.getters.language.data.shop_items.shop_item_barcode}}</th>
								<th>Date</th>
								<th>Type</th>
								<th>Qty</th>
								<th>Note</th>
								<th>Edited by</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(shop_item_move,index) in shop_item_moves" :key="index">
								<td>{{shop_item_move.shop_item_id}}</td>
								<td>
									{{ new Date(shop_item_move.shop_item_move_date).toISOString().split('T')[0]}} {{ new Date(shop_item_move.shop_item_move_date).toISOString().split('T')[1].split('.')[0]}}

								</td>
								<td>{{shop_item_move.shop_item_move_type}}</td>
								<td>{{shop_item_move.shop_item_move_quantity}}</td>
								<td>{{shop_item_move.shop_item_move_note}}</td>
								<td>{{shop_item_move.user_username}}</td>
							</tr>
						</tbody>
					</table>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text class="elevation-0" @click="move_dialog = false">
						{{$store.getters.language.data.shop_items.cancel_btn}}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="print_dialog" persistent fullscreen>
			<v-card class="">
				<v-card-title class="no-print">
					Print Label
				</v-card-title>
				<v-card-text class="">
					<!-- {{selected_shop_items}} -->
					<!-- <p>{{selected_shop_items.shop_item_price}}</p> -->
					<div class="text-center mt-4 print">
						<p class="text-center">{{selected_shop_items.shop_item_price}}</p>
						<barcode :value="selected_shop_items.shop_item_id" format="CODE128" :height="50" :width="2" :displayValue="true"></barcode>
					</div>
				</v-card-text>
				<v-card-actions class="no-print">
					<v-spacer></v-spacer>
					<v-btn text class="elevation-0" @click="print_dialog = false">
						{{$store.getters.language.data.shop_items.cancel_btn}}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-snackbar v-model="snackbar.value" :color="snackbar.color">
			{{snackbar.text}}
		</v-snackbar>
	</div>
</template>
<script>
	import requests from './../../requests/shop_items.request.js'
	import filerequests from './../../requests/file_attachments.request.js'
	import historyrequests from './../../requests/shop_item_histories.request.js'
	import moverequests from './../../requests/shop_item_moves.request.js'
	import VueBarcode from 'vue-barcode';


	export default {
		data() {
			return {
				shop_items: {
					shop_item_link: '',
				},
				file_attachments: {},
				shop_item_histories: {},
				shop_item_moves: {},
				shop_item_moves_add: {
				},
				img_file: null,
				search: '',
				loading: true,
				loading_btn: false,
				selected_rows: [],
				snackbar: {
					value: false,
					text: '',
					color: ''
				},
				rows: [],
				fileRows: [],
				selected_shop_items: {},
				delete_dialog: false,
				image_dialog: false,
				history_dialog: false,
				move_dialog: false,
				print_dialog: false,
				headers: [

					{
						text: this.$store.getters.language.data.shop_items.shop_item_barcode,
						align: 'start',
						sortable: true,
						value: 'shop_item_id',
					},
					{
						text: this.$store.getters.language.data.shop_items.shop_item_quantity,
						align: 'start',
						sortable: true,
						value: 'shop_item_quantity',
					},
					{
						text: this.$store.getters.language.data.shop_items.shop_item_note,
						align: 'start',
						sortable: true,
						value: 'shop_item_note',
					},
					{
						text: this.$store.getters.language.data.shop_items.shop_item_link,
						align: 'start',
						sortable: true,
						value: 'shop_item_link',
					},
					{
						text: this.$store.getters.language.data.shop_items.shop_item_price,
						align: 'start',
						sortable: true,
						value: 'shop_item_price',
					},
					{
						text: this.$store.getters.language.data.shop_items.shop_item_fav,
						align: 'start',
						sortable: true,
						value: 'shop_item_fav',
					},
					{
						text: this.$store.getters.language.data.shop_items.shop_item_status,
						align: 'start',
						sortable: true,
						value: 'shop_item_status',
					},
					{
						text: this.$store.getters.language.data.shop_items.shop_item_date,
						align: 'start',
						sortable: true,
						value: 'shop_item_date',
					},
					{
						text: this.$store.getters.language.data.item_types.item_type_title,
						align: 'start',
						sortable: true,
						value: 'item_type_title',
					},
					{
						text: this.$store.getters.language.data.item_colors.item_color_title,
						align: 'start',
						sortable: true,
						value: 'item_color_title',
					},
					{
						text: this.$store.getters.language.data.item_sizes.item_size_title,
						align: 'start',
						sortable: true,
						value: 'item_size_title',
					},
					{
						text: this.$store.getters.language.data.users.user_username,
						align: 'start',
						sortable: true,
						value: 'user_username',
					},
					{
						text: this.$store.getters.language.data.actions,
						align: 'start',
						sortable: true,
						value: 'shop_item_barcode',
					},
				],
			}
		},
		components: {
			'barcode': VueBarcode

		},
		computed: {
			item_types() {
				return this.$store.getters.item_types_list
			},
			item_colors() {
				return this.$store.getters.item_colors_list
			},
			item_sizes() {
				return this.$store.getters.item_sizes_list
			},
			users() {
				return this.$store.getters.users_list
			},
			user() {
				return this.$store.getters.user
			},

		},
		mounted() {
			this.readShopItems();
			this.shop_items.user_id = this.user.user_id
		},
		methods: {
			openFile() {
				this.$refs.file.click();
			},
			processFile($event) {
				this.img_file = $event.target.files[0];
			},
			addFileAttachments() {
				this.loading_btn = true
				var formData = new FormData();
				formData.append('shop_item_id', this.file_attachments.shop_item_id);
				formData.append('user_id', this.file_attachments.user_id);
				formData.append('file_attachment_path', this.img_file);

				filerequests.createFileAttachments(formData).then(r => {
					if (r.status == 200) {
						this.readFileAttachments(this.file_attachments)
						this.file_attachments = {}
						this.file_attachments.user_id = this.user.user_id
						this.file_attachments.shop_item_id = this.selected_shop_items.shop_item_id
						this.snackbar = {
							value: true,
							text: 'FileAttachments Added',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to add FileAttachments',
							color: 'error'
						}
					}
				})
					.finally(() => {
						this.loading_btn = false
					})

			},
			addShopItems() {
				this.loading_btn = true

				if (this.shop_items.shop_item_link == "") {
					this.shop_items.shop_item_link = null
				}
				else if (this.shop_items.shop_item_link != null && this.shop_items.shop_item_link != "") {
					// split the shop_item_link and get the last part of the link starting from http and http included
					this.shop_items.shop_item_link = this.shop_items.shop_item_link.split("http")[1]
					// add http to the shop_item_link
					this.shop_items.shop_item_link = "http" + this.shop_items.shop_item_link
					console.log(this.shop_items.shop_item_link)
				}

				requests.createShopItems(this.shop_items).then(r => {
					if (r.status == 200) {
						this.shop_items = {}
						this.shop_items.user_id = this.user.user_id

						this.rows.unshift(
							r.data.new_data
						)
						this.snackbar = {
							value: true,
							text: 'ShopItems Added',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to add ShopItems',
							color: 'error'
						}
					}
				})
					.finally(() => {
						this.loading_btn = false
					})

			},
			deleteShopItems(shop_item_id) {
				requests.deleteShopItems(shop_item_id).then(r => {
					this.delete_dialog = false
					if (r.status == 200) {
						this.rows = this.rows.filter(f => {
							return f.shop_item_id != shop_item_id
						})
						this.snackbar = {
							value: true,
							text: 'ShopItems Deleted',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Delete Faild',
							color: 'error'
						}
					}
				})
			},

			deleteShopItemsList() {
				let ids = this.selected_rows.map(m => m.shop_item_id)
				requests.deleteShopItemsList(ids).then(r => {
					if (r.status == 200) {
						this.rows = this.rows.filter(f => {
							return ids.indexOf(f.shop_item_id) == -1
						})
						this.snackbar = {
							value: true,
							text: this.selected_rows.length + ' ShopItems Deleted',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Delete Faild',
							color: 'error'
						}
					}
				})
			},

			readShopItems() {
				this.loading = true
				requests.getAllShopItems().then(r => {
					if (r.status == 200) {
						this.rows = r.data.rows
						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read ShopItems',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read ShopItems',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},
			readFileAttachments(i) {
				this.loading = true
				var item_id = i.shop_item_id
				filerequests.getFileAttachmentsByColumn('shop_item_id', item_id).then(r => {
					if (r.status == 200) {
						this.fileRows = r.data.rows
						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read FileAttachments',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read FileAttachments',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},
			readShopItemHistories(i) {
				this.loading = true
				var item_id = i.shop_item_id
				historyrequests.getShopItemHistoriesByColumn('shop_item_id', item_id).then(r => {
					if (r.status == 200) {
						this.shop_item_histories = r.data.rows
						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read ShopItemHistories',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read ShopItemHistories',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},
			readShopItemMoves(i) {
				this.loading = true
				var item_id = i.shop_item_id
				moverequests.getShopItemMovesByColumn('shop_item_id', item_id).then(r => {
					if (r.status == 200) {
						this.shop_item_moves = r.data.rows
						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read ShopItemHistories',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read ShopItemHistories',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},
			addShopItemMoves() {
				this.shop_item_moves_add.user_id = this.user.user_id
				this.loading_btn = true
				console.log(this.shop_item_moves)
				moverequests.createShopItemMoves(this.shop_item_moves_add).then(r => {
					if (r.status == 200) {
						this.readShopItemMoves(this.shop_item_moves_add)
						this.shop_item_moves_add = {
							shop_item_id: this.shop_item_moves_add.shop_item_id,
							user_id: this.user.user_id,
						}
						this.rows.push(
							r.data.new_data
						)
						this.snackbar = {
							value: true,
							text: 'ShopItemMoves Added',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to add ShopItemMoves',
							color: 'error'
						}
					}
				})
					.finally(() => {
						this.loading_btn = false
					})

			},

			selectShopItems(i) {
				this.selected_shop_items = i
				this.delete_dialog = true
			},
			openDialog(i) {
				this.selected_shop_items = i
				this.file_attachments.shop_item_id = i.shop_item_id
				this.file_attachments.user_id = this.user.user_id
				this.readFileAttachments(i)
				this.image_dialog = true
			},
			openDialogHistory(i) {
				this.selected_shop_items = i
				this.shop_item_histories.shop_item_id = i.shop_item_id
				this.readShopItemHistories(i)
				this.history_dialog = true
			},
			openDialogMove(i) {
				this.selected_shop_items = i
				this.shop_item_moves_add.shop_item_id = i.shop_item_id

				this.readShopItemMoves(i)
				this.move_dialog = true
			},
			openDialogBarcode(i) {
				this.selected_shop_items = i
				// this.shop_item_moves_add.shop_item_id = i.shop_item_id

				this.print_dialog = true
			},
			pasteTextLink() {
				//   create another clipboard paste 
				navigator.clipboard.readText().then(pastedText => {
					this.shop_items.shop_item_link = pastedText;
					// You can also set the text directly in the input field using the text field reference
					// this.$refs.textField.internalValue = pastedText;
				});

			},
			reinsertItem(item) {
				this.shop_items = item
				delete this.shop_items.shop_item_id
				delete this.shop_items.shop_item_date
				delete this.shop_items.shop_item_quantity
				delete this.shop_items.user_username
				delete this.shop_items.item_type_title
				delete this.shop_items.item_color_title
				delete this.shop_items.item_size_title

				this.shop_items.user_id = this.user.user_id
			},
		},
	}
</script>
<style scoped>
  

    @media print {
        .no-print {
            display: none;
        }

    }
</style>
